<template lang="pug">
.layout
  GoUpArrow
  MainNavbar
  .container.box-container
    <slot />
</template>

<script setup>
import { useSettingsStore } from '~/stores/settings'

const settingsStore = useSettingsStore()
await settingsStore.getLangTree()
</script>

<style lang="scss" scoped>
.layout {
  --bcg-color: var(--light-color);
  --text-color: var(--dark-color);
  --bcg-menu-color: var(--light-color);

  width: 100%;
  position: relative;
  min-height: 100vh;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
}

.container {
  max-width: 100%;
  width: 100%;
  padding: 90px 20px 20px;
  position: relative;

  @media (--small-range-viewport) {
    max-width: 960px;
    padding: 160px 0 80px;
  }

  @media (--medium-viewport-mf) {
    max-width: 1024px;
    padding: 160px 0 80px;
  }

  @media (--large-viewport-mf) {
    max-width: 1440px;
    padding: 170px 123px 80px;
  }
}
</style>
